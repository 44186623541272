import React, { useState, useEffect, useRef } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './App.css';
import axios from 'axios';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const localizer = momentLocalizer(moment);

// Custom компонентата за header-ите
const CustomHeader = ({ label }) => {
  switch (label) {
    case 'Sun':
      return <div>Недела <br /> <span className="headerSpan">Overcoming Challenges</span></div>;
    case 'Mon':
      return <div>Понеделник <br /> <span className="headerSpan">Goal Setting</span></div>;
    case 'Tue':
      return <div>Вторник <br /> <span className="headerSpan">Time Management</span></div>;
    case 'Wed':
      return <div>Среда <br /> <span className="headerSpan">Positive Mindset</span></div>;
    case 'Thu':
      return <div>Четврток <br /> <span className="headerSpan">Mindfulness</span></div>;
    case 'Fri':
      return <div>Петок <br /> <span className="headerSpan">Self-Confidence</span></div>;
    case 'Sat':
      return <div>Сабота <br /> <span className="headerSpan">Communication Skills</span></div>;
    default:
      return <div>{label}</div>;
  }
};

function App() {
  const [events, setEvents] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const calendarRef = useRef(null);

  useEffect(() => {
    axios.get('https://content.marcada.work/get-articles.php')
      .then(response => {
        const fetchedEvents = response.data.map(article => {
          const eventDate = moment(article.publish_date, 'DD.MM.YYYY');
  
          return {
            title: `${article.category_number} - ${article.article_number} - ${article.title}`,
            category_number: article.category_number,
            article_number: article.article_number,
            category: article.category,
            author: article.author,
            blog_post_outline: article.blog_post_outline, // Веќе парсирано од PHP
            start: eventDate.toDate(),
            end: eventDate.toDate(),
            allDay: true,
            className: `event-day-${eventDate.day()}`,
          };
        });
  
        setEvents(fetchedEvents);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);
  

  const openModal = (event) => {
    setSelectedEvent(event);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedEvent(null);
  };

  const generateAndCopyPrompt = () => {
    if (!selectedEvent) return;

    const { title, author, blog_post_outline } = selectedEvent;
    const prompt = `You are an expert content creator for a self-improvement blog. Your task today is to write a new post titled "${title}" in the style of ${author}. Please follow these instructions: 
1. Title: ${title}
2. Author of the Day: ${author}
   ◦ Author Name’s Style and Tone: Emulate ${author}’s distinctive style, focusing on motivation, clarity, and actionable advice.
3. Blog Post Outline:
${blog_post_outline}
4. Writing Guidelines:
   ◦ Authenticity: While the content should be written in a neutral voice, ensure the style mimics ${author}’s writing closely.
   ◦ Actionable Advice: Include practical tips and strategies that readers can implement immediately.
   ◦ Empathy and Encouragement: The post should inspire readers, reflecting ${author}’s positive and motivational approach.
   ◦ Quotes: Use quotes from ${author} sparingly and only when they perfectly fit the context. Do not repeat quotes.
Final Instructions:
   • Begin by setting the context for the reader, then explore the topic deeply using the provided outline. Conclude with a motivational call to action that leaves the reader inspired.
   • Review your work to ensure there are no repetitive quotes and that the content flows smoothly without unnecessary repetition.
NOTE 1: Do not include anything unrelated to the task.
NOTE 2: Use quotes only when they add significant value and relevance to the content.
NOTE 3: Double-check that each quote is accurate and not repeated.

Следи ги следните инструкции:
1. Прашај ме дали сум задоволен и доколку сум задоволен, напиши POST EXCERPT.
2. Прашај ме дали сум задоволен и доколку сум задоволен, напиши META DESCRIPTION.
3. Прашај ме дали сум задоволен и доколку сум задоволен, напиши TAGS (ВО ЕДНА ЛИНИЈА, одвоени со запирка!).`;

    navigator.clipboard.writeText(prompt).then(() => {
      alert("Prompt copied to clipboard!");
    });
  };

  return (
    <div className="container" ref={calendarRef}>
      <h1>Content Calendar</h1>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 'auto', minHeight: 750 }}
        eventPropGetter={(event) => ({
          className: event.className,
        })}
        onSelectEvent={openModal}
        components={{
          month: {
            header: CustomHeader,
          },
        }}
      />

      {/* Material UI Dialog */}
      <Dialog open={modalIsOpen} onClose={closeModal} fullWidth maxWidth="sm">
        {/* DialogTitle со точен приказ */}
        <DialogTitle>{`${selectedEvent?.title} - ${selectedEvent?.category}`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <strong>Author:</strong> {selectedEvent?.author}
          </DialogContentText>
          <DialogContentText>
            <strong>Blog Post Outline:</strong>
            <ul style={{ paddingLeft: '20px' }}>
              {Array.isArray(selectedEvent?.blog_post_outline)
                ? selectedEvent.blog_post_outline.map((item, index) => (
                  <li key={index} style={{ marginBottom: '10px', listStyleType: 'none' }}>
                    {item.replace(/"/g, '')} {/* Отстрануваме ги наводниците */}
                  </li>
                ))
                : selectedEvent?.blog_post_outline}
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={generateAndCopyPrompt} color="primary" variant="contained">
            Generate and copy prompt
          </Button>
          <Button onClick={closeModal} color="secondary" variant="outlined">
            Exit
          </Button>
        </DialogActions>
      </Dialog>



    </div>
  );
}

export default App;
